import React from "react";

export default function Footer() {
  return (
    <div className="footer">
      {"\u00A9"} UNLINKLY.COM
      {/* <a href="https://mishraa.com" style={{ fontSize: "12px" }}>
        by MISHRAA.COM
      </a> */}
    </div>
  );
}
