import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAbodICJoYPfALHERfKR2VgBXy6eg4Bu9k",
  authDomain: "one-link-mishraa.firebaseapp.com",
  projectId: "one-link-mishraa",
  storageBucket: "one-link-mishraa.appspot.com",
  messagingSenderId: "804793378747",
  appId: "1:804793378747:web:e1aa0ada4532c38bf1469e",
};

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
// };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
