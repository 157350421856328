import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import { auth, db } from "../Firebase-config";
import { getDoc, doc, collection, setDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import Lottie from "lottie-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signup = () => {
  const { createUser, user } = UserAuth();
  const { value } = useContext(UserContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCpassword] = useState("");
  const [userName, setUsername] = useState([]);
  const [name, setName] = useState([]);
  const [users] = useState([]);
  const [exist, setExist] = useState("");
  const navigate = useNavigate();

  const correct_way = /^[a-z0-9._]*$/gi;
  const onlyText = /[a-z]/;

  const collRef = collection(db, "Users");

  useEffect(() => {
    if (value) {
      setUsername(value);
    }
  }, []);

  const addValues = async () => {
    await setDoc(doc(collRef, userName), {
      UserInfo: {
        Email: email.trim(),
        Name: name.trim(),
        Phone: "",
        Username: userName.trim(),
      },
    });

    await updateProfile(auth.currentUser, { displayName: userName.trim() });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const err1 = document.querySelector(".err1");
    const err2 = document.querySelector(".err2");

    if (err1.innerHTML === "" && err2.innerHTML === "") {
      try {
        await createUser(email, password);
        await addValues();
        navigate("/Dashboard");
      } catch (e) {
        if (e.message.includes("email")) {
          // alert("Email already in use");
          toast.error("Email already in use.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else {
          alert("Signup Error: " + e.message);
        }
      }
    } else {
      // alert("Please resolve all the errors and try again");
      toast.error("Please resolve all the errors and try again", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  };

  const checkUsername = async (e) => {
    const a = e.target.value;
    const lowerText = a.toLowerCase().trim().replace(" ", "_");

    if (lowerText.match(correct_way)) {
      setUsername(lowerText.trim());

      const docRef = doc(collRef, a.trim());
      const singleSnapshot = await getDoc(docRef);
      const singleDoc = [...users, singleSnapshot.data()];

      if (singleDoc[0]) {
        setExist(a);
      } else {
        setExist("");
      }
    }
  };

  const handleError = () => {
    let message = "";
    if (userName.length > 0) {
      if (userName.length < 3) {
        message = "Username must be atleast 3 characters";
      } else if (!onlyText.test(userName)) {
        message = "Username must contain atleast one alphabets";
      } else if (
        userName.charAt(0) === "." ||
        userName.charAt(userName.length - 1) === "."
      ) {
        message = "Username cannot start or end with a period";
      } else if (userName.includes("..")) {
        message = "Username can not contain consecutive periods";
      } else if (exist) {
        message = "Username '" + exist + "' is already in use";
      } else {
        message = "";
      }
    }

    return message;
  };

  const checkPass = () => {
    let message = "";

    if (password.length > 0 && password.length < 6) {
      message = "Password must be atleast 6 characters long";
    } else if (password !== cPassword && cPassword !== "" && password !== "") {
      message = "Password do not match";
    }

    return message;
  };

  if (user) {
    navigate("/dashboard");
  } else {
    return (
      <div>
        <ToastContainer />
        <div className="signupContainer">
          <Lottie
            className="lottie"
            animationData={require("../images/signup.json")}
          />
          <h1>Hi, let's get started!</h1>
          <h3>
            Create a new account
            {/* <Link to="/login" className="linkText">
              LOGIN
            </Link> */}
          </h3>

          <div className="signupCard">
            <form onSubmit={handleSubmit}>
              <span className="label">Name</span>
              <input
                type="text"
                name=""
                onChange={(e) => {
                  setName(e.target.value);
                }}
                id=""
                placeholder="Your Name"
                required
              />

              <span className="label">Username</span>
              <input
                type="text"
                name=""
                onChange={checkUsername}
                id="username"
                value={userName}
                placeholder="Unique Username"
                required
              />

              <h4 className="error err1">{handleError()}</h4>

              <span className="label">Email</span>
              <input
                type="email"
                name=""
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                id=""
                placeholder="Your Email"
                required
              />

              <span className="label">Password</span>
              <input
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
                name=""
                id=""
                placeholder="Your Password"
                required
              />

              <span className="label">Confirm Password</span>
              <input
                onChange={(e) => {
                  setCpassword(e.target.value);
                }}
                type="password"
                name=""
                id=""
                placeholder="Confirm Your Password"
                required
              />

              <h4 className="error err2">{checkPass()}</h4>
              <button type="submit" className="btn">
                Create Account
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default Signup;
