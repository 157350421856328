import React from "react";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";

export default function Four04() {
  return (
    <div className="signupContainer">
      <center>
        <Lottie animationData={require("../images/notfound.json")} />
        <h1>Error 404</h1>
        <h3>
          Create a new account
          <br /> <br />
          <Link to="/signup" className="btn">
            SIGNUP
          </Link>
        </h3>
      </center>
    </div>
  );
}
