import { React, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { UserAuth } from "../context/AuthContext";

export default function Home() {
  const { setValue } = useContext(UserContext);
  const { user } = UserAuth();
  const navigate = useNavigate();

  if (user) {
    navigate("/dashboard");
  } else {
    function Body() {
      const [name, setName] = useState();
      const handleOnChange = (e) => {
        const a = e.target.value;
        const lowerText = a.toLowerCase().replace(" ", "_");
        setName(lowerText);
      };

      const goTo = () => {
        setValue(name);
        navigate("/" + name);
      };

      const handleSubmit = (e) => {
        e.preventDefault();

        goTo();
      };

      return (
        <div className="body">
          {/* <Lottie className="anim" animationData={anim} /> */}
          <h1 className="heroHeading">
            {/* Everything you are. In <br /> <span>one</span> simple{" "}
            <span>link</span>. */}
            All your <span>bio</span> links, In <br /> <span>one</span> simple{" "}
            <span>link</span>.
          </h1>

          <h3 className="heroText">
            Join and share everything you create, curate and sell online. All
            from the one link in bio.
          </h3>

          <form onSubmit={handleSubmit} className="inputArea">
            <div className="textArea">
              <span>unlinkly.com/</span>
              <input
                className="nameInput"
                type="text"
                name="nameToSearch"
                id="nameToSearch"
                placeholder="yourname"
                value={name}
                required
                onChange={handleOnChange}
              />
            </div>

            <button className="btn btnClaim">Claim Your Link</button>
          </form>
        </div>
      );
    }

    return (
      <div className="box">
        <Body />
      </div>
    );
  }
}
