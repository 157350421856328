import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import Lottie from "lottie-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const { userLogin, user } = UserAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [btnValue, setBtnvalue] = useState("login");

  const navigate = useNavigate();

  const handleBack = () => {
    const lblE = document.getElementById("labelEmail");
    const inpE = document.getElementById("email");

    const lblP = document.getElementById("labelPass");
    const inpP = document.getElementById("pass");

    const btnBack = document.getElementById("btnBack");

    lblE.classList.remove("hidden");
    inpE.classList.remove("hidden");
    setBtnvalue("continue");

    lblP.classList.add("hidden");
    inpP.classList.add("hidden");
    btnBack.classList.add("hidden");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (btnValue === "continue") {
      const lblE = document.getElementById("labelEmail");
      const inpE = document.getElementById("email");

      const lblP = document.getElementById("labelPass");
      const inpP = document.getElementById("pass");

      const btnBack = document.getElementById("btnBack");

      lblE.classList.add("hidden");
      inpE.classList.add("hidden");
      setBtnvalue("login");

      lblP.classList.remove("hidden");
      inpP.classList.remove("hidden");
      btnBack.classList.remove("hidden");
    } else {
      try {
        await userLogin(email.trim(), password);
        navigate("/Dashboard");
      } catch (e) {
        if (e.message === "Firebase: Error (auth/user-not-found).") {
          // alert("No User Found");
          toast.error("No User Found", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else if (e.message === "Firebase: Error (auth/wrong-password).") {
          // alert("Wrong Password");
          toast.error("Wrong Password", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else if (e.message === "Firebase: Error (auth/invalid-email).") {
          // alert("Invalid Email");
          toast.error("Invalid Email", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else {
          // alert("Please check your internet connection and try again.");
          // alert(e.message);
          toast.error("Please check your internet connection and try again.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      }
    }
  };

  if (user) {
    navigate("/dashboard");
  } else {
    return (
      <div>
        <ToastContainer />
        <div className="signupContainer">
          <Lottie
            className="lottie hello welcome"
            animationData={require("../images/hello.json")}
          />
          <h1>Welcome Back! </h1>
          <h3>
            Sign in to Continue
            {/* <Link to="/signup" className="linkText">
              SIGNUP
            </Link> */}
          </h3>

          <div className="signupCard">
            <button
              className="hidden"
              style={{
                height: "25px",
                width: "70px",
                fontSize: "12px",
                borderRadius: "50px",
                backgroundColor: "black",
                color: "white",
              }}
              id="btnBack"
              onClick={handleBack}
            >
              back
            </button>
            <form onSubmit={handleSubmit}>
              <span id="labelEmail" className="label">
                Email
              </span>
              <input
                type="email"
                name=""
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                id="email"
                placeholder="Your Email"
                required
              />

              <span id="labelPass" className="label">
                Password
              </span>
              <input
                className=""
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                type="password"
                name=""
                id="pass"
                placeholder="Your Password"
              />

              <button type="submit" className="btn">
                {/* {btnValue} */}
                LOGIN
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default Login;
