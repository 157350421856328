import { React, useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../Firebase-config";
import { getDoc, doc } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OpenApp from "react-open-app";

export default function Profile(props) {
  const { username } = useParams();
  const [users, setUsers] = useState([]);
  const { user } = UserAuth();
  const { value } = useContext(UserContext);

  const nav = useNavigate();

  let uName = "";
  if (username) {
    uName = username;
  } else {
    uName = props.data;
  }

  const copyText = () => {
    navigator.clipboard.writeText("https://unlinkly.com/" + uName);

    toast.success("Link Copied! ", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const docRef = doc(db, "Users", uName);

  const userInfo = async () => {
    const singleSnapshot = await getDoc(docRef);
    const singleDoc = [...users, singleSnapshot.data()];
    setUsers(singleDoc);
  };

  useEffect(() => {
    userInfo();
  }, []);

  const ChildDiv = () => {
    return (
      <div className="pContainer">
        {users.map((item) => {
          if (item === undefined) {
            if (user) {
              nav("/notfound");
            } else {
              if (value) {
                nav("/signup");
              } else {
                nav("/notfound");
              }
            }
          } else {
            return (
              <div className="parent col" key="">
                {user && localStorage.getItem("uName") === uName ? (
                  <h4 onClick={copyText} className="copyIcon">
                    Copy Your UnLinkly Link and Share It Anywhere
                    <img
                      className="icon"
                      src={require("../images/copy.png")}
                      alt=""
                    />
                  </h4>
                ) : null}
                <br />
                <div className="bgContainer">
                  <div className="firsCard">
                    <h3 className="sub-text-for-white username">
                      @{item.UserInfo.Username}
                    </h3>
                    <h1 className="name">{item.UserInfo.Name}</h1>

                    <h3 className="bio sub-text-for-white">
                      {!item.UserInfo.Bio
                        ? "thank you for visiting"
                        : item.UserInfo.Bio}
                    </h3>

                    <hr />

                    <div className="contactCard">
                      {item.UserInfo.Phone && (
                        <div className="row">
                          <img
                            className="icon"
                            src={require("../images/phone.png")}
                            alt=""
                          />
                          <h3 className="contact">{item.UserInfo.Phone}</h3>
                        </div>
                      )}

                      {item.UserInfo.Email && (
                        <div className="row">
                          <img
                            className="icon"
                            src={require("../images/email.png")}
                            alt=""
                          />
                          <h3 className="contact">{item.UserInfo.Email}</h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <h4 style={{ marginTop: "20px" }}> Find me on </h4>

                <ul className="topFive row">
                  {item.Social &&
                    Object.keys(item.Social).map((element) => {
                      const a = item.Social[element];
                      if (element.toLowerCase().trim() === "facebook") {
                        return (
                          <li>
                            <a href={a.Link} target="_blank" rel="noreferrer">
                              <img
                                className="icon social"
                                src={require("../images/facebook.png")}
                                alt=""
                              />
                            </a>
                          </li>
                        );
                      } else if (element.toLowerCase().trim() === "instagram") {
                        return (
                          <li>
                            <a href={a.Link} target="_blank" rel="noreferrer">
                              <img
                                className="icon social"
                                src={require("../images/instagram.png")}
                                alt=""
                              />
                            </a>
                          </li>
                        );
                      } else if (element.toLowerCase().trim() === "twitter") {
                        return (
                          <li>
                            <a href={a.Link} target="_blank" rel="noreferrer">
                              <img
                                className="icon social"
                                src={require("../images/twitter.png")}
                                alt=""
                              />
                            </a>
                          </li>
                        );
                      } else if (element.toLowerCase().trim() === "youtube") {
                        return (
                          <li>
                            <a href={a.Link} target="_blank" rel="noreferrer">
                              <img
                                className="icon social"
                                src={require("../images/youtube.png")}
                                alt=""
                              />
                            </a>
                          </li>
                        );
                      } else if (element.toLowerCase().trim() === "snapchat") {
                        return (
                          <li>
                            <a href={a.Link} target="_blank" rel="noreferrer">
                              <img
                                className="icon social"
                                src={require("../images/snapchat.png")}
                                alt=""
                              />
                            </a>
                          </li>
                        );
                      }
                    })}
                </ul>

                <ul className="extraLinks col">
                  <li>
                    {item.Social &&
                      Object.keys(item.Social).map((element) => {
                        if (
                          element.toLowerCase().trim() !== "userinfo" &&
                          element.toLowerCase().trim() !== "facebook" &&
                          element.toLowerCase().trim() !== "instagram" &&
                          element.toLowerCase().trim() !== "youtube" &&
                          element.toLowerCase().trim() !== "twitter" &&
                          element.toLowerCase().trim() !== "snapchat" &&
                          element.toLowerCase().trim() !== "spotify"
                        ) {
                          const a = item.Social[element];
                          return (
                            <li>
                              <a href={a.Link} target="_blank" rel="noreferrer">
                                <img
                                  className="icon social"
                                  src={require("../images/link.jpg")}
                                  alt=""
                                />
                                <div className="linkDetails">
                                  <span className="linkName">{a.Name}</span>
                                  <span className="linkDesc">
                                    {a.Description}
                                  </span>
                                </div>
                                <img
                                  className="icon arrow"
                                  src={require("../images/arrow.png")}
                                  alt=""
                                />
                              </a>
                            </li>
                          );
                        }
                      })}
                  </li>
                </ul>

                <div className="spotify">
                  {item.Social &&
                    Object.keys(item.Social).map((element) => {
                      if (element.toLowerCase() === "spotify") {
                        const a = item[element];
                        const link = a.Link;
                        const breakAt = link.indexOf("playlist/");
                        const secondPart = link.slice(breakAt);
                        const afterEmbed = "embed/" + secondPart;
                        const firstPart = link.substr(0, breakAt);
                        const word = firstPart + afterEmbed;
                        return (
                          <iframe
                            src={word}
                            width="100%"
                            height="352"
                            allowfullscreen=""
                            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                            loading="lazy"
                            title="spotify"
                          ></iframe>
                        );
                      }
                    })}
                </div>
              </div>
            );
          }
        })}
        <ToastContainer />
      </div>
    );
  };

  return (
    <div>
      <ChildDiv />
    </div>
  );
}
