import React, { useState, useEffect } from "react";
import { db } from "../Firebase-config";
import { getDoc, doc, updateDoc, collection } from "firebase/firestore";
import Lottie from "lottie-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateProfile() {
  const [phone, setPhone] = useState("");
  const [userName, setUsername] = useState([]);
  const [email, setEmail] = useState([]);
  const [name, setName] = useState([]);
  const [bio, setBio] = useState([]);
  const [users, setUsers] = useState([]);

  let uName = localStorage.getItem("uName");
  const collRef = collection(db, "Users");
  const docRef = doc(collRef, uName);

  const userInfo = async () => {
    const singleSnapshot = await getDoc(docRef);
    const singleDoc = [...users, singleSnapshot.data()];
    setUsers(singleDoc);

    singleDoc.map((i) => {
      setName(i.UserInfo.Name.trim());
      setPhone(i.UserInfo.Phone.trim());
      setEmail(i.UserInfo.Email.trim());
      setBio(i.UserInfo.Bio.trim());
      setUsername(i.UserInfo.Username.trim());
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await updateDoc(doc(collRef, userName), {
        UserInfo: {
          Username: userName.trim(),
          Email: email.trim(),
          Name: name.trim(),
          Bio: bio.trim(),
          Phone: (phone + "").trim(),
        },
      });

      toast.success("Updated Successfull.", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const notEditableStyle = {
    border: "none",
  };

  useEffect(() => {
    userInfo();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="signupContainer">
        <Lottie
          style={{ marginLeft: "0px", marginTop: "20px" }}
          className="lottie hello welcome"
          animationData={require("../images/update.json")}
        />
        <h1 style={{ fontSize: "50px", marginTop: "20px" }}>Update Profile</h1>
        <div
          className="signupCard"
          style={{ paddingTop: "0px", paddingBottom: "25px" }}
        >
          <form onSubmit={handleSubmit}>
            <span className="label">Username</span>
            <input
              type="text"
              style={notEditableStyle}
              name=""
              id=""
              placeholder="Name"
              value={"@" + userName}
              required
            />

            <span className="label">Email</span>
            <input
              type="email"
              style={notEditableStyle}
              name=""
              id=""
              value={email}
              placeholder="Email"
              required
            />

            <span className="label">Name</span>
            <input
              type="text"
              name=""
              onChange={(e) => {
                setName(e.target.value);
              }}
              id=""
              placeholder="Name"
              value={name}
              required
            />

            <span className="label">Bio</span>
            <input
              type="text"
              name=""
              onChange={(e) => {
                setBio(e.target.value);
              }}
              id=""
              placeholder="Something about you"
              value={bio}
              required
            />

            <span className="label">Phone</span>
            <input
              type="text"
              name=""
              pattern="^[0-9]*[.,]?[0-9]*$"
              onChange={(e) => {
                setPhone(e.target.value.replace(/\D/g, ""));
              }}
              maxLength="15"
              id=""
              placeholder="Phone"
              value={phone}
            />
            <button type="submit" className="btn">
              Update Profile
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default UpdateProfile;
