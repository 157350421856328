import { useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { auth } from "../Firebase-config";
import { sendEmailVerification } from "firebase/auth";
import { useEffect, useState } from "react";
import Profile from "./Profile";
import Lottie from "lottie-react";

export default function Dashboard() {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dName, setDName] = useState("");

  const open = () => {
    navigate("../AddLink");
  };

  const checkEmailVerification = async () => {
    try {
      if (!auth.currentUser.emailVerified) {
        await sendEmailVerification(auth.currentUser);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const Dashboard = () => {
    return (
      <div className="updateProfile">
        {loading ? (
          <div className="signupContainer">
            <center>
              <h2>LOADING....</h2>

              <Lottie animationData={require("../images/verify-email.json")} />
              <h3>Please verify your email!</h3>
            </center>
          </div>
        ) : (
          <>
            <Profile data={dName} />

            <Lottie
              className="btnAdd"
              onClick={open}
              animationData={require("../images/btnAdd.json")}
            />
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    checkEmailVerification();

    try {
      if (user) {
        if (auth.currentUser.emailVerified) {
          setVerified(true);

          if (verified) {
            setLoading(false);
            setDName(auth.currentUser.displayName);
            localStorage.setItem("uName", auth.currentUser.displayName);
          }
        } else {
          setVerified(false);
        }
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.log(error.message);
    }
  });

  return (
    <div>
      <Dashboard />
      {/* <AddLink trigger={show} /> */}
    </div>
  );
}
