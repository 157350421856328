import React, { useState } from "react";
import { db } from "../Firebase-config";
import { setDoc, doc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddLink() {
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("https://");
  const [description, setDescription] = useState("");
  const [uName] = useState(localStorage.getItem("uName").trim());
  const nav = useNavigate();

  const collRef = collection(db, "Users");

  const cancel = () => {
    nav("/dashboard");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await setDoc(
        doc(collRef, uName.trim()),
        {
          Social: {
            [title.trim()]: {
              Name: title.trim(),
              Link: link.trim(),
              Description: description.trim(),
            },
          },
        },
        { merge: true }
      );

      setTitle("");
      setLink("https://");
      setDescription("");
      // alert("Link Added Successfully");
      toast.success("Link Added Successfully", {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <section className="popup">
      <ToastContainer />
      <div className="signupContainer" style={{ paddingTop: "25px" }}>
        <h1>Add Links</h1>
        <br />
        <h4>Choose from pre-made links</h4>
        <ul className="builtInSocial">
          <li>
            <img
              className="icon"
              src={require("../images/facebook.png")}
              alt=""
              onClick={() => {
                setLink("https://facebook.com/");
                setTitle("Facebook");
              }}
            />
          </li>
          <li>
            <img
              className="icon"
              src={require("../images/instagram.png")}
              alt=""
              onClick={() => {
                setLink("https://instagram.com/");
                setTitle("Instagram");
              }}
            />
          </li>
          <li>
            <img
              className="icon"
              src={require("../images/snapchat.png")}
              alt=""
              onClick={() => {
                setLink("https://Snapchat.com/");
                setTitle("Snapchat");
              }}
            />
          </li>
          <li>
            <img
              className="icon"
              src={require("../images/youtube.png")}
              alt=""
              onClick={() => {
                setLink("https://youtube.com/@");
                setTitle("Youtube");
              }}
            />
          </li>
          <li>
            <img
              className="icon"
              src={require("../images/twitter.png")}
              alt=""
              onClick={() => {
                setLink("https://twitter.com/");
                setTitle("Twitter");
              }}
            />
          </li>
          <li>
            <img
              className="icon"
              src={require("../images/whatsapp.png")}
              alt=""
              onClick={() => {
                setLink("https://wa.me/");
                setTitle("Whatsapp");
              }}
            />
          </li>
        </ul>

        <div
          className="signupCard"
          style={{
            padding: "0 25px 25px 25px",
            marginBottom: "30px",
            marginTop: "20px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <span className="label">Title</span>
            <input
              type="text"
              name=""
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              id=""
              required
              placeholder="Eg: website"
            />
            <span className="label">URL</span>
            <input
              type="text"
              name=""
              onChange={(e) => setLink(e.target.value)}
              id=""
              required
              value={link}
              placeholder="Eg: https://mishraa.com"
            />

            <span className="label">About the Link</span>
            <input
              type="text"
              name=""
              onChange={(e) => setDescription(e.target.value)}
              id=""
              value={description}
              placeholder="Eg: This is my website"
            />

            <button type="submit" className="btn" style={{ marginTop: "30px" }}>
              Add Link
            </button>

            <button
              type="submit"
              className="btn"
              onClick={cancel}
              style={{
                backgroundColor: "white",
                color: "black",
                marginTop: "20px",
              }}
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
